import React from "react";

const Ebook = ({ color }) => {
  return (
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 174.9 176.3"
      fill={color}
    >
      <g>
        <polygon
          points="135.7,140.4 135.7,113.4 130.4,113.4 130.4,140.9 121.3,131.9 117.5,135.7 130.4,148.5 132.8,150.9 132.8,150.8 
		133.9,149.7 135.5,148.2 135.7,148.2 135.7,147.9 148,135.7 144.3,131.9 	"
        />
        <rect x="69.7" y="30.1" width="31.3" height="6" />
        <rect x="58.7" y="53.6" width="54.7" height="6" />
        <path
          d="M137,90.1V5.2V4.7V0.2H22c-12.2,0-22.1,10-22.1,22.2v125.2h0.7c-0.4,1.8-0.7,3.6-0.7,5.5c0,12.6,10.2,22.8,22.8,22.8h104
		c1.6,0.2,3.3,0.3,5,0.3c23.8,0,43.2-19.4,43.2-43.2C174.9,111,158.4,92.8,137,90.1z M4.9,22.3c0-9.4,7.7-17.1,17.1-17.1h9.4v125.1
		h-8.7c-7.2,0-13.6,3.3-17.8,8.6C4.9,123.7,4.9,22.3,4.9,22.3z M22.7,170.9c-9.8,0-17.8-8-17.8-17.8s8-17.8,17.8-17.8h65.7v-5H36.7
		V5.2h95v84.6c-23.9,0-43.2,19.4-43.2,43.2c0,6.3,1.3,12.2,3.8,17.6H26.7v6h68.8c3.9,5.9,9.2,10.9,15.4,14.3H22.7z M131.7,170.7
		c-20.8,0-37.6-16.8-37.6-37.6s16.8-37.6,37.6-37.6s37.6,16.8,37.6,37.6C169.3,153.8,152.5,170.7,131.7,170.7z"
        />
      </g>
    </svg>
  );
};

export default Ebook;
